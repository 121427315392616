import { Platform } from "react-native";
import { PERMISSIONS, check, request } from "react-native-permissions";
import messaging from "@react-native-firebase/messaging";
import PushNotificationIOS from "@react-native-community/push-notification-ios";

let PushNotification;
if (Platform.OS !== "web") {
  PushNotification = require("react-native-push-notification");
}
import Logger from "library/utils/logger";
class FCMService {
  register = (onRegister, onNotification) => {
    // FCM Registration
    this.checkPermission(onRegister, onNotification);

    if (Platform.OS === "android") {
      const channel_id = "mhq_notifications";

      PushNotification.channelExists(channel_id, function (exists) {
        if (!exists) {
          PushNotification.createChannel({
            channelId: channel_id, // (required)
            channelName: "Mercury HQ Notifications", // (required)
            channelDescription: "Mercury HQ Notifications channel", // (optional) default: undefined.
            playSound: true, // (optional) default: true
            soundName: "default", // (optional) See `soundName` parameter of `localNotification` function
            //importance: Importance.HIGH, // (optional) default: Importance.HIGH. Int value of the Android notification importance
            vibrate: true, // (optional) default: true. Creates the default vibration patten if true.
          });
        }
      });
    }
  };

  showLocalNotification = (id, title, message, data = {}, options = {}) => {
    PushNotification.localNotification({
      ...this.buildNotificationPayload(id, data, options),

      /* iOS and Android properties */
      title: title || "", // (optional)
      message: message || "", // (required)
      userInfo: {}, // (optional) default: {} (using null throws a JSON value '<null>' error)
      playSound: true, // (optional) default: true
      soundName: "default", // (optional) Sound to play when the notification is shown. Value of 'default' plays the default sound. It can be set to a custom sound such as 'android.resource://com.xyz/raw/my_sound'. It will look for the 'my_sound' audio file in 'res/raw' directory and play it. default: 'default' (default sound is played)
      userInteraction: false,
    });
  };

  buildNotificationPayload = (id, data = {}, options = {}) => {
    return Platform.OS === "android"
      ? {
          id: id,
          channelId: "mhq_notifications",
          autoCancel: true, // (optional) default: true
          largeIcon: "ic_launcher", // (optional) default: "ic_launcher". Use "" for no large icon.
          smallIcon: "ic_notification", // (optional) default: "ic_notification" with fallback for "ic_launcher". Use "" for default small icon.
          vibrate: true, // (optional) default: true
          vibration: 300, // vibration length in milliseconds, ignored if vibrate=false, default: 1000
          priority: "high", // (optional) set notification priority, default: high
          importance: options.importance || "high",
          data: data,
          color: "#114258",
        }
      : {
          alertAction: options.alertAction || "view",
          category: options.category || "",
          userInfo: {
            id: id,
            item: data,
          },
        };
  };

  clearAllNotifications = () => {
    PushNotification.cancelAllLocalNotifications();
    PushNotification.removeAllDeliveredNotifications();
    Platform.OS === "ios" &&
      PushNotificationIOS.removeAllDeliveredNotifications();
  };

  removeAllDeliveredNotificationByID = (notificationID) => {
    PushNotification.cancelLocalNotifications({ id: `${notificationID}` });
  };

  checkPermission = async (onRegister, onNotification) => {
    try {
      // Check if the app is running on Android 13 (API Level 33) or higher
      if (Platform.OS === "android" && Platform.Version >= 33) {
        const postNotificationPermission =
          PERMISSIONS.ANDROID.POST_NOTIFICATIONS;
        const postNotificationStatus = await check(postNotificationPermission);
        if (postNotificationStatus !== "granted") {
          const permissionRequestResult = await request(
            postNotificationPermission
          );
          if (permissionRequestResult !== "granted") {
            onRegister({
              status: messaging.AuthorizationStatus.DENIED,
              fcmToken: null,
            });
            return;
          }
        }
      }
      const authorizationStatus = await messaging().hasPermission();

      if (
        authorizationStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authorizationStatus === messaging.AuthorizationStatus.PROVISIONAL
      ) {
        this.getToken(onRegister, onNotification);
      } else if (authorizationStatus === messaging.AuthorizationStatus.DENIED) {
        // Need to enable notification permission in settings to receive notifications.
        onRegister({
          status: messaging.AuthorizationStatus.DENIED,
          fcmToken: null,
        });
      } else {
        const permissionGranted = await messaging().requestPermission({
          alert: true,
          sound: true,
          announcement: true,
          badge: true,
          provisional: false,
          carPlay: true,
        });

        if (permissionGranted === messaging.AuthorizationStatus.DENIED) {
          // Need to enable notification permission in settings to receive notifications.
          onRegister({
            status: messaging.AuthorizationStatus.DENIED,
            fcmToken: null,
          });
        } else {
          this.getToken(onRegister, onNotification);
        }
      }
    } catch (error) {
      Logger.error(
        `Failed to get device token/ FCM token for mobile => ${Platform.OS}`,
        JSON.stringify(error)
      );
      onRegister({
        status: messaging.AuthorizationStatus.DENIED,
        fcmToken: null,
      });
    }
  };

  getToken = async (onRegister, onNotification) => {
    // After permission granted register device for sending remote notification is required to get the device token.
    await messaging().registerDeviceForRemoteMessages();
    messaging()
      .getToken()
      .then((fcmToken) => {
        if (fcmToken) {
          // Device token received
          onRegister({
            status: messaging.AuthorizationStatus.AUTHORIZED,
            fcmToken,
          });

          // Registering FCM Notification Listeners
          this.createNotificationListeners(onRegister, onNotification);
        } else {
          // User doesn't have a device token
          onRegister({
            status: messaging.AuthorizationStatus.DENIED,
            fcmToken: null,
          });
        }
      })
      .catch((error) => {
        //Get Token Rejected
        onRegister({
          status: messaging.AuthorizationStatus.DENIED,
          fcmToken: null,
        });
      });
  };

  getDeviceToken = async () => {
    const authorizationStatus = await messaging().hasPermission();

    if (authorizationStatus === messaging.AuthorizationStatus.DENIED) {
      // Need to enable notification permission in settings to receive notifications.
      return "";
    } else {
      const fcmToken = await messaging().getToken();

      if (fcmToken) {
        return fcmToken;
      } else {
        return "";
      }
    }
  };

  // deleteToken = () => {
  //   messaging()
  //     .deleteToken()
  //     .catch((error) => {
  //       console.log("Delete FCM Token Error :>> ", error);
  //     });
  // };

  onNotificationOpenedListener = (onOpenNotification) => {
    messaging().onNotificationOpenedApp((remoteMessage) => {
      if (remoteMessage) {
        onOpenNotification(remoteMessage);
      }
    });

    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (remoteMessage) {
          const notification = remoteMessage.notification;
          onOpenNotification(notification);
        }
      });

    // Only for local notifications opened when the application is in the foreground
    PushNotification.configure({
      onNotification: function (notification) {
        notification.foreground &&
          notification.userInteraction &&
          onOpenNotification(notification);
      },
    });
  };

  createNotificationListeners = (onRegister, onNotification) => {
    messaging().onMessage(async (remoteMessage) => {
      if (remoteMessage) {
        let notification = null;
        notification = remoteMessage.notification;
        onNotification(notification);
      }
    });

    messaging().onTokenRefresh((fcmToken) => {
      onRegister({
        status: messaging.AuthorizationStatus.AUTHORIZED,
        fcmToken,
      });
    });
  };

  unRegister = () => {
    PushNotification.unregister();
  };
}

export const fcmService = new FCMService();
