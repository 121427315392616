import React from "react";
import { Text } from "react-native-elements";
import { Linking, Platform, View } from "react-native";

import { fonts, colors } from "styles/theme";
import tw from "tailwind-rn";
import moment from "moment";
import { compare as generateJSONPatch } from "fast-json-patch";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import last from "lodash/last";
import set from "lodash/set";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import pick from "lodash/pick";

import styles from "./styles";

import I18NContext from "library/contexts/i18N";
import { ToasterHandler } from "components/elements";

import {
  formatPrice,
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";
import { getPrice, getDiscount } from "library/utils/createOrder";
import { basicPaymentInfo } from "../create-order/config";
import { getCardType } from "library/utils/payment-options";
import { digitalGiftCardProducts } from "library/utils/giftCardProducts";
import { orderDsRequestRerouting } from "library/utils/orderListing";
import UserProfileStorage from "library/storage/userProfile";

export const keyMap = {
  CASH_OR_CHECK: "Cash/Check",
  INVOICE: "Invoice",
  PAID_ELSEWHERE: "Paid Elsewhere",
  CREDIT_CARD: "Credit Card",
  SAVED_CARD: "Saved Payment",
  GIFT_CARD: "Gift Card",
  PAY_LATER: "Pay Later",
};

export const getSupportedOrderActions = (listingType, isLocalOrder) => {
  if (["delivery", "nonRouted"].includes(listingType)) {
    return {
      dateChange: false,
      priceChange: false,
      hideChatSend: true,
    };
  } else {
    if (isLocalOrder) {
      return {
        dateChange: false,
        priceChange: false,
        hideChatSend: true,
      };
    } else {
      return {
        dateChange: true,
        priceChange: true,
        hideChatSend: false,
      };
    }
  }
};

export const generateContent = (
  data,
  suppressNull = false,
  isNormalFontWeight = false,
  dataStyles = {},
  valueText = {},
  customLabelStyle = {},
  customValueStyle = {}
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = React.useContext(I18NContext);
  return Object.keys(data).map((key) => {
    const value = data[key] || "";
    const valueStyle = dataStyles[key] || {};
    if (!value && suppressNull) return;
    return (
      <View
        style={{ ...tw("flex flex-row"), zIndex: -1 }}
        key={key}
        fsClass={
          key === "Phone" || key === "Recipient Phone" || key === "Email"
            ? "fs-exclude"
            : "fs-unmask"
        }
      >
        <Text
          style={
            isNormalFontWeight
              ? { ...fonts.default, ...styles.labelText, ...customLabelStyle }
              : { ...fonts.heading5, ...styles.labelText, ...customLabelStyle }
          }
          testID={key}
        >
          {Localise(messages, key)}
        </Text>
        {typeof value === "object" ? (
          <View>
            {Object.keys(value).map((key) => (
              <Text
                style={{ ...tw("pb-1"), ...customValueStyle }}
                key={`${key}-value`}
                testID={`${key}-value`}
              >
                {value[key]}
              </Text>
            ))}
          </View>
        ) : (
          <Text
            style={{
              ...styles.valueText,
              ...valueText,
              ...(valueStyle.isNegative ? { color: colors.red } : {}),
              ...customValueStyle,
            }}
            testID={`${key}-value`}
          >
            {valueStyle.isPrice
              ? `${
                  valueStyle.isNegative
                    ? `($${formatPrice(value)})`
                    : `$${formatPrice(value)}`
                }`
              : value}
          </Text>
        )}
      </View>
    );
  });
};

export const getActionObj = (
  deliveryDate,
  actionType,
  price,
  preferredDeliveryType
) => {
  if (
    actionType !== "reject" &&
    actionType !== "delivery-confirmation" &&
    actionType !== "price-approval" &&
    actionType !== "price-deny" &&
    actionType !== "delivery-date-approval" &&
    actionType !== "delivery-date-deny" &&
    actionType !== "cancel-approval" &&
    actionType !== "cancel-deny" &&
    actionType !== "transfer" &&
    actionType !== "preferred-delivery-type"
  )
    return {};

  //const { deliveryInfo = {} } = orderDetails;
  const isPriceApproval = actionType === "price-approval";
  const isPriceChangeDeny = actionType === "price-deny";
  const isDateChangeApproval = actionType === "delivery-date-approval";
  const isDateChangeDeny = actionType === "delivery-date-deny";
  const isCancelApproval = actionType === "cancel-approval";
  const isCancelDeny = actionType === "cancel-deny";
  const isReject = actionType === "reject";
  const isDeliveryConfirmation = actionType === "delivery-confirmation";
  const isTransfer = actionType === "transfer";
  const isDeliveryTypeChange = actionType === "preferred-delivery-type";

  if (isDeliveryConfirmation) {
    return {
      comments: "",
      deliveryDate: deliveryDate,
      deliveredDate: moment().format("YYYY-MM-DD"),
      deliveryTime: moment().format("HH:mm"),
    };
  } else if (isTransfer) {
    return {
      comments: "",
      reason: "This is transfer",
    };
  } else if (isDeliveryTypeChange) {
    return {
      preferredDeliveryType,
    };
  }

  return {
    ...((isReject || isDeliveryConfirmation) && {
      comments: isReject ? "Rejecting Order" : "Delivered product to customer",
    }),
    reason: isPriceApproval
      ? "Price Change Approval"
      : isPriceChangeDeny
      ? "Price Change Deny"
      : isDateChangeApproval
      ? "Delivery Date Change Approval"
      : isDateChangeDeny
      ? "Delivery Date Change Deny"
      : isCancelApproval
      ? "Cancel Approval"
      : isCancelDeny
      ? "Cancel Deny"
      : isReject
      ? "Rejecting Order"
      : undefined,
    deliveryDate: deliveryDate,
    ...(isPriceApproval && { price }),
  };
};

export const getRequestObj = (
  orderDetails,
  requestType,
  itemPrice,
  deliveryDate,
  comments = "",
  reason = "",
  operator = ""
) => {
  const isPriceChange = requestType === "price";

  const orderReq = {
    price: isPriceChange ? itemPrice : undefined,
    comments: comments,
    reason: reason,
    operator: operator,
    deliveryDate: deliveryDate,
  };

  return orderReq;
};

export const getOrderChatInfo = (
  direction = "",
  messageType = "",
  forwardedMember = "",
  text = "",
  forwardSendingMember = "",
  status = ""
) => {
  const isDateChangeRequest =
    messageType === "DeliveryDate" && direction === "INBOUND";
  const isPriceChangeRequest =
    messageType === "Price" && direction === "INBOUND";
  const isCancelRequest = messageType === "Cancel" && direction === "INBOUND";
  const isStructuredMessage =
    isDateChangeRequest || isPriceChangeRequest || isCancelRequest;
  const isForwardedMessage = messageType === "Transfer" && status !== "ERROR";
  const isNonMHQPriceMessage =
    direction === "INBOUND" &&
    messageType === "PriceApproval" &&
    !/^Price Change Approval:/.test(text);
  const isNonMHQPriceRequest =
    direction === "INBOUND" &&
    messageType === "Price" &&
    !/^Price Adjustment: Please approve for/.test(text);

  const headingTitle = isDateChangeRequest
    ? "Date Change Request"
    : isPriceChangeRequest
    ? "Price Change Request"
    : messageType === "Cancel"
    ? "Cancellation Request"
    : messageType === "CancelDeny"
    ? "Cancellation Request Denied"
    : messageType === "CancelApproval"
    ? "Cancellation Request Approved"
    : messageType === "Reject"
    ? "Order Rejected"
    : messageType === "Adjustment"
    ? "Adjustment submitted"
    : isForwardedMessage
    ? `Order has been transferred from ${forwardSendingMember} to ${forwardedMember}`
    : "";

  const approveRequestAction = isDateChangeRequest
    ? "delivery-date-approval"
    : isPriceChangeRequest
    ? "price-approval"
    : "cancel-approval";

  const approveRequestReason = isDateChangeRequest
    ? "Delivery Date Change Approval"
    : isPriceChangeRequest
    ? "Price Change Approval"
    : "Cancel Approval";

  const denyRequestAction = isDateChangeRequest
    ? "delivery-date-deny"
    : isPriceChangeRequest
    ? "price-deny"
    : "cancel-deny";

  const denyRequestReason = isDateChangeRequest
    ? "Delivery Date Change Deny"
    : isPriceChangeRequest
    ? "Price Change Deny"
    : "Cancel Deny";

  return {
    approveRequestAction,
    approveRequestReason,
    denyRequestAction,
    denyRequestReason,
    headingTitle,
    isStructuredMessage,
    isNonMHQPriceMessage,
    isNonMHQPriceRequest,
  };
};

export const startNavigation = (
  {
    addressLine1 = "",
    city = "",
    country = "",
    state = "",
    latitude = "",
    longitude = "",
  },
  messages,
  Localise
) => {
  let url =
    latitude && longitude
      ? `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${parseFloat(
          latitude
        )},${parseFloat(longitude)}`
      : `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${encodeURIComponent(
          addressLine1
        )},${encodeURIComponent(city)},${encodeURIComponent(
          state
        )},${encodeURIComponent(country)}}`;

  Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        ToasterHandler(
          "error",
          Localise(
            messages,
            "Unable to start the navigation. Please try again."
          )
        );
      } else {
        return Platform.OS === "web" ? window.open(url) : Linking.openURL(url);
      }
    })
    .catch((err) => {
      console.error("An error occurred", err);
      ToasterHandler(
        "error",
        Localise(messages, "Unable to start the navigation. Please try again.")
      );
    });
};

export const triggerNativeActions = (recipientInfo, actionKey) => {
  const { phone: recipientPhone } = recipientInfo;

  let phoneNumber = "";
  if (actionKey === "callRecipient") {
    phoneNumber = `tel:${"+1" + recipientPhone}`;
  } else if (actionKey === "textRecipient") {
    phoneNumber = `sms:${"+1" + recipientPhone}`;
  }

  Linking.canOpenURL(phoneNumber)
    .then(() => {
      Linking.openURL(phoneNumber);
    })
    .catch(() => console.log("Unable to open dialer app."));
};

const fetchLocationTypeAndName = (deliveryInfo = {}, recipientInfo = {}) => {
  const {
    addressLine1 = "",
    locationType = "",
    locationName = "",
  } = recipientInfo;

  const {
    deliveryMethod = "",
    locationType: dsLocationType = "",
    locationName: dsLocationName = "",
  } = deliveryInfo;

  let locType = locationType,
    locName = locationName;

  if (locationType === "" && locationName === "") locType = "Residence";

  if (
    [
      "FLORIST_PARTNER",
      "MOL_FLORIST_DELIVERED",
      "FOL_FLORIST_DELIVERED",
    ].includes(deliveryMethod)
  ) {
    if (locName && locName.length > 0 && locType === "") locType = "Office";

    // Giving high preference for location type and name which are selected while submitting order to DS
    if (dsLocationType && dsLocationType.length > 0) locType = dsLocationType;
    if (dsLocationName && dsLocationName.length > 0) locName = dsLocationName;
  }

  if (deliveryMethod !== "STORE_PICKUP" && deliveryMethod !== "WALK_IN") {
    if (locType === "Office") locType = "Business";

    if (locType === "Funeral") locType = "Funeral Home";
  }

  return {
    locationType: locType,
    locationName: locName,
    addressLine1,
  };
};

export const hasPaymentProcessingError = ({
  settlementDetails = {},
  paymentStatus = "",
  checkSettlementDetails = true, // Flag to include or exclude settlementDetails check
}) => {
  // If checkSettlementDetails is true, check if settlementDetails is empty
  const isSettlementDetailsEmpty = checkSettlementDetails
    ? isEmpty(settlementDetails)
    : false;

  // Define the list of payment status codes that indicate errors
  const errorStatuses = [
    "SETTLEMENT_ERROR",
    "AUTHORIZATION_FAILED",
    "CC_DETAILS_MISSING",
    "HA_NOT_FOUND",
    "HA_CREDIT_LIMIT_EXCEEDED",
  ];
  return isSettlementDetailsEmpty || errorStatuses.includes(paymentStatus);
};

export const getOrderDetailsInitialValues = (orderResponse = {}) => {
  const hasCustomerInfo =
    Object.values(orderResponse?.customerInfo ?? {}).filter((val) => !!val)
      .length > 0;
  const priceIncludingDelivery = formatPrice(
    orderResponse?.priceIncludingDelivery
  );
  const orderTotalPrice = formatPrice(orderResponse?.orderTotalPrice || 0);

  let orderItems = [];
  let hasSettlementError = false;
  let multiProductEligible = false;

  orderResponse.orderItems.map((orderItem) => {
    const isFloristDelivered =
      (orderItem?.deliveryInfo?.deliveryMethod ?? "") === "FLORIST_DELIVERED";
    const isDSSubmitted =
      (orderItem?.deliveryInfo?.deliveryType ?? "") === "Delivery Service";
    const isFloristSubmitted =
      (orderItem?.deliveryInfo?.preferredDeliveryType ?? "") ===
      "FLORIST_FULFILLED";

    hasSettlementError = orderItem?.hasSettlementError || false;
    multiProductEligible = orderItem?.multiProductEligible || false;

    const fetchDeliveryType = () => {
      if (
        (orderItem?.hasDSFulfillmentError ?? false) ||
        orderDsRequestRerouting(pick(orderItem, ["latestDSRequestedStatus"]))
      ) {
        return "";
      } else if (
        ["ACKNOWLEDGED", "ACKNOWLEDGE_PRINT", "DESIGN", "DESIGNED"].includes(
          orderItem?.status ?? ""
        ) &&
        orderItem?.dsEligible &&
        !isDSSubmitted &&
        !isFloristSubmitted
      ) {
        return orderItem?.autoSendToDS
          ? "DELIVERY_SERVICE"
          : "FLORIST_DELIVERED";
      } else if (isDSSubmitted) {
        return "DELIVERY_SERVICE";
      }

      return orderItem?.deliveryInfo?.deliveryMethod;
    };

    // delivery info section modifications
    const relatedMessages = filter(
      orderItem?.messages,
      (message) => message.messageType === "DeliveryDate"
    );
    const latestMessage = last(relatedMessages);
    const {
      direction: requestDirection = "",
      requestStatus,
      deliveryDate: date = "",
      id: askId = "",
    } = latestMessage || {};

    const dateChangeInfo = { value: date, askId };
    if (requestDirection === "INBOUND" && requestStatus === "WAITING")
      dateChangeInfo.status = "APPROVE_DENY";
    else if (requestDirection === "OUTBOUND" && requestStatus === "WAITING")
      dateChangeInfo.status = "PENDING APPROVAL";
    else dateChangeInfo.status = requestStatus;
    const deliveryDate =
      dateChangeInfo.status === "APPROVE_DENY"
        ? orderItem?.deliveryInfo?.previousDeliveryDate
        : orderItem?.deliveryInfo?.deliveryDate;
    const newDeliveryDate = orderItem?.deliveryInfo?.deliveryDate;
    const pickUpDateTime = orderItem?.deliveryInfo?.pickUpDateTime;
    const { storePickupDateTime = "" } = orderItem?.pickupInfo || {};
    const dateTime = storePickupDateTime || pickUpDateTime;

    //Updating Line Items
    const updatedLineItems = [];
    let addonsTotalPrice = 0;
    const lineItems = cloneDeep(orderItem?.lineItems ?? []);

    lineItems.map((lineItem) => {
      let productFirstChoiceDescription =
        lineItem?.productFirstChoiceDescription?.split("\\n")?.join("\n") ?? "";

      const productPrice = formatPrice(
        lineItem?.lineItemAmounts?.find(
          (item) => item.name == "retailProductAmount"
        )?.value || 0
      );
      const lineItemRefundedAmount =
        lineItem?.lineItemAmounts?.find((obj) => obj.name === "refundedAmount")
          ?.value || ``;

      const productQuantity = lineItem?.quantity || 1;
      const productItemPrice = formatPrice(
        parseFloat(productPrice) / productQuantity
      );

      const productLineItem = {
        productFirstChoiceCode: lineItem.productFirstChoiceCode,
        productFirstChoiceDescription,
        productSecondChoiceCode: lineItem?.productSecondChoiceCode || "",
        productSecondChoiceDescription:
          lineItem?.productSecondChoiceDescription || "",
        designNotes: lineItem?.designNotes ?? "",
        price: productItemPrice,
        itemPrice: productItemPrice,
        originalPrice: productPrice,
        canRemove: multiProductEligible,
        newPrice: "",
        quantity: productQuantity.toString(),
        originalQuantity: productQuantity.toString(),
        type: lineItem.type || "",
        isRemoved: false,
        newlyAdded: false,
        refundAmount: "",
        refundDeliveryFee: "",
        refundAvailablePerItem: productPrice - lineItemRefundedAmount || 0,
      };
      updatedLineItems.push(productLineItem);

      const accessories = cloneDeep(lineItem.accessories) || [];
      accessories.map((accessory) => {
        const accessoryPrice = formatPrice(getPrice(accessory.accessoryPrice));
        const accessoryQuantity = accessory?.accessoryQty || 1;
        const accessoryItemPrice = formatPrice(
          accessoryPrice / accessoryQuantity
        );

        const accessoryLineItem = {
          productFirstChoiceCode: accessory.accessoryId,
          productFirstChoiceDescription: accessory.accessoryDesc,
          productSecondChoiceCode: "NONE",
          productSecondChoiceDescription: "NONE",
          designNotes: accessory?.designNotes ?? "",
          price: accessoryItemPrice,
          itemPrice: accessoryItemPrice,
          originalPrice: accessoryPrice,
          canRemove: multiProductEligible,
          newPrice: "",
          quantity: accessoryQuantity.toString(),
          originalQuantity: accessoryQuantity.toString(),
          type: "Addon",
          isRemoved: false,
          newlyAdded: false,
        };

        addonsTotalPrice += parseFloat(accessoryLineItem.price);
        updatedLineItems.push(accessoryLineItem);
      });
    });

    const {
      locationType = "",
      locationName = "",
      addressLine1 = "",
    } = fetchLocationTypeAndName(
      orderItem?.deliveryInfo,
      orderItem?.recipientInfo
    );

    orderItems.push({
      deliveryInfo: {
        deliveryMethod: fetchDeliveryType(),
        deliveryDate,
        newDeliveryDate,
        occasion: orderItem.deliveryInfo.occasion || "",
        cardMessage: orderItem.deliveryInfo.cardMessage || "",
        deliveryInstructions: orderItem.deliveryInfo.deliveryInstructions || "",
        pickUpBy: orderItem.deliveryInfo.pickUpBy || "",
        pickupDate: deliveryDate ? moment(deliveryDate).format("MM/DD/YY") : "",
        pickUpDateTime: dateTime
          ? moment(dateTime).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        shopDayTimings: {},
        isRushOrder: false,
        locationType: orderItem.deliveryInfo.locationType || "",
        locationName: orderItem.deliveryInfo.locationName || "",
        deliveryDetailsToDS: orderItem.deliveryInfo.deliveryDetailsToDS || "",
        undeliverableAction: orderItem.deliveryInfo.undeliverableAction || "",
        recipientInfo: {
          firstName: orderItem.recipientInfo.firstName || "",
          lastName: orderItem.recipientInfo.lastName || "",
          phone: orderItem.recipientInfo.phone || "",
          addressLine1: addressLine1 || "",
          addressLine2: orderItem.recipientInfo.addressLine2 || "",
          city: orderItem.recipientInfo.city || "",
          state: orderItem.recipientInfo.state || "",
          zip: orderItem.recipientInfo.zip || "",
          country: orderItem.recipientInfo.country || "",
          deliveryMethod: fetchDeliveryType(), // added this line in order access deliveryMethod while doing Yup validations for recipientInfo
          addressVerificationInfo: orderItem.addressVerificationInfo || {},
          locationType,
          locationName,
        },
        isFloristDelivered,
        isDSSubmitted,
        isFloristSubmitted,
        requestDirection,
        dateChangeInfo,
      },
      productInfo: {
        updatedLineItems,
        specialInstructions: orderItem.specialInstructions || "",
      },
      orderPrice: priceIncludingDelivery,
      promoCode: orderResponse?.promoCode,
      addonsTotalPrice: formatPrice(addonsTotalPrice),
    });
  });

  const paymentMethods = get(orderResponse, "paymentDetails.paymentMethod", []);
  let processedAmount = Number(0);
  paymentMethods.map((payment) => {
    const {
      paymentMethodDetails: {
        authorizationDetails = {},
        settlementDetails = {},
      },
      paymentStatus = "",
    } = payment;
    const hasProcessingError = hasPaymentProcessingError({
      settlementDetails,
      paymentStatus,
    });

    if (!hasProcessingError) {
      processedAmount += formatPrice(
        authorizationDetails?.find((obj) => obj.name === "amount")?.value || 0
      );
    }
  });

  return {
    customerInfo: hasCustomerInfo
      ? {
          ...orderResponse.customerInfo,
          initialCustomerId: orderResponse.customerInfo?.customerId,
          phone:
            orderResponse?.customerInfo?.phones?.length > 0
              ? phoneNumberFormatter(orderResponse?.customerInfo?.phones[0])
              : "",
          customerType:
            orderResponse?.customerInfo?.isBusinessProfile ||
            orderResponse?.customerInfo?.customerType === "Business"
              ? "Business"
              : "Individual",
          newTaxExemptCode: "",
        }
      : {},
    orderItems,
    paymentInfo: {
      hasSettlementError,
      amountDue: hasSettlementError
        ? formatPrice(orderTotalPrice - processedAmount)
        : 0,
      newTotalAmount: hasSettlementError
        ? formatPrice(orderTotalPrice - processedAmount)
        : 0,
      newSubTotalAmount: 0,
      amountChargedToCustomer: hasSettlementError ? orderTotalPrice : 0,
      refundAvailable: orderResponse.refundAvailable,
      newDeliveryFee: "",
      newRelayFee: "",
      newServiceFee: "",
      newRetransFee: "",
      newRetailDeliveryFee: "",
      newDiscount: "",
      formattedDiscount: "",
      paymentType: "",
      applyTaxOnDF: false,
      applyTaxOnRDF: false,
      saveBillingInfo: false,
      newTaxes: {
        newTaxAmount: 0,
        newTaxRate: 0.0,
        newTaxSplits: [],
      },
      oldValues: {
        totalTax: 0,
        totalDeliveryFee: 0,
        retailDeliveryFee: 0,
        relayFee: 0,
        serviceFee: 0,
        retransFee: 0,
        totalDiscount: 0,
      },
      CASH_OR_CHECK: {
        tenderedAmount: "",
        changeDueAmount: "",
      },
      PAID_ELSEWHERE: {
        amount: "",
        note: "",
      },
      PAY_LATER: {
        amount: "",
        note: "",
      },
      CREDIT_CARD: {
        name: "",
        cardNumber: "",
        actualCardNumber: "",
        expDate: "",
        cvv: "",
        zip: "",
        billingInfo: {
          addressLine1: "",
          suite: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        },
      },
    },
  };
};

export const getOrderCurrentSubTotal = (updatedLineItems = []) => {
  let newTotalPrice = 0;

  updatedLineItems.map((lineItem) => {
    const { price = "0.0", newPrice, quantity = "1" } = lineItem;
    if (newPrice) {
      newTotalPrice += parseFloat(getPrice(newPrice));
    } else {
      newTotalPrice += parseFloat(getPrice(price) * parseInt(quantity));
    }
  });

  return parseFloat(formatPrice(newTotalPrice));
};

export const getRefundAmountTotal = (
  updatedLineItems = [],
  paymentsInfoItems = [],
  totalTaxRate,
  taxRate,
  taxOnDelivery,
  taxAmount
) => {
  let newTotalPrice = 0;
  const {
    newDeliveryFee,
    newRelayFee,
    newServiceFee,
    newRetransFee,
    newDeliveryTip = 0,
    newRushDeliveryFee = 0,
  } = paymentsInfoItems;

  const deliveryRefund =
    Number(newDeliveryFee) +
    Number(newRelayFee) +
    Number(newServiceFee) +
    Number(newDeliveryTip) +
    Number(newRushDeliveryFee) +
    Number(newRetransFee);

  updatedLineItems.map((lineItem) => {
    const { refundAmount } = lineItem;
    if (refundAmount) {
      newTotalPrice += parseFloat(getPrice(refundAmount));
    }
  });
  const newRefundedPrice =
    newTotalPrice + deliveryRefund - Number(newDeliveryTip);
  let newTaxRate =
    totalTaxRate > 0
      ? taxOnDelivery
        ? formatPrice((newRefundedPrice * taxRate) / 1000)
        : formatPrice((newTotalPrice * taxRate) / 1000)
      : 0;
  if (Number(newTaxRate) > Number(taxAmount)) {
    newTaxRate = Number(taxAmount);
  }
  const finalTotalPrice = formatPrice(newTotalPrice);
  return Number(finalTotalPrice) + deliveryRefund + Number(newTaxRate);
};

export const getPaymentInfo = (values, tokenId) => {
  let {
    paymentType: paymentMethodType = "",
    CASH_OR_CHECK,
    CREDIT_CARD,
    PAID_ELSEWHERE,
    PAY_LATER,
    saveBillingInfo,
    INVOICE,
    PAYMENT_TERMINAL = {},
  } = get(values, "paymentInfo", {});

  let details = {},
    billingInformation = {
      ...basicPaymentInfo.paymentMethod[0].billingInformation,
    };

  const orderTotal = values?.paymentInfo?.amountDue;

  if (paymentMethodType === "CREDIT_CARD") {
    const {
      actualCardNumber = "",
      expDate,
      name,
      zip = "",
      billingInfo = {},
    } = CREDIT_CARD;

    const [expirationMonth, expirationYear] = expDate.split("/");
    const trimmedCardNumber = actualCardNumber.split(" ").join("");

    details = {
      nameOnCard: name,
      creditCardType: getCardType(actualCardNumber)?.toUpperCase(),
      creditCardNumber: trimmedCardNumber.substring(
        trimmedCardNumber.length - 4
      ),
      creditCardExpireMonth: expirationMonth,
      creditCardExpireYear: expirationYear,
      creditCardEncrypted: false,
      authorizationDetails: [
        {
          name: "amount",
          value: formatPrice(orderTotal),
        },
      ],
      ...(saveBillingInfo ? { tokenId } : {}),
    };

    billingInformation = {
      ...billingInfo,
      zip,
    };
  } else if (paymentMethodType === "SAVED_CARD") {
    const { cardNumber = "", zip = "", billingInfo = {} } = CREDIT_CARD;
    const { cardType, expirationYear, expirationMonth, nameOnCard } = get(
      values,
      "customerInfo.firstPaymentInfo",
      {}
    );

    details = {
      ...details,
      nameOnCard,
      creditCardType: cardType,
      creditCardNumber: cardNumber.substring(cardNumber.length - 4),
      creditCardExpireMonth: expirationMonth,
      creditCardExpireYear: expirationYear,
      creditCardEncrypted: false,
      authorizationDetails: [
        {
          name: "amount",
          value: formatPrice(orderTotal),
        },
      ],
    };

    billingInformation = {
      ...billingInfo,
      zip,
    };
  } else if (paymentMethodType === "PAYMENT_TERMINAL") {
    const { zip = "", billingInfo = {} } = CREDIT_CARD;

    details = omit(PAYMENT_TERMINAL, ["isHardwareTerminal"]);

    billingInformation = {
      ...billingInfo,
      zip,
    };
  } else if (paymentMethodType === "CASH_OR_CHECK") {
    const { changeDueAmount, tenderedAmount } = CASH_OR_CHECK;
    details = {
      tenderedAmount,
      changeDueAmount,
    };
  } else if (paymentMethodType === "PAID_ELSEWHERE") {
    const { amount, note } = PAID_ELSEWHERE;
    details = {
      amount,
      note,
    };
  } else if (paymentMethodType === "PAY_LATER") {
    const { amount, note } = PAY_LATER;
    details = {
      amount,
      note,
    };
  } else if (paymentMethodType === "INVOICE") {
    const { amount, notes } = INVOICE;
    details = {
      amount,
      notes,
    };
  }

  return {
    paymentMethodType,
    paymentMethodDetails: details,
    billingInformation,
  };
};

export const getOrderPatchUpdates = (
  orderDetailsRes = {},
  formValues,
  tokenId
) => {
  const updatedOrderDetailsRes = cloneDeep(orderDetailsRes);

  const values = cloneDeep(formValues);

  let { customerInfo = {}, orderItems = [], paymentInfo = {} } = values;

  const {
    newDiscount,
    newDeliveryFee,
    newRetailDeliveryFee,
    newServiceFee,
    newRetransFee,
    newSubTotalAmount,
    amountChargedToCustomer,
    newTaxes: { newTaxAmount = 0, newTaxRate = 0.0, newTaxSplits = [] },
    oldValues: {
      totalTax: oldTotalTax = 0,
      totalDeliveryFee: oldTotalDeliveryFee = 0,
      retailDeliveryFee: oldRetailDeliveryFee = 0,
      relayFee = 0,
      serviceFee: oldServiceFee = 0,
      retransFee: oldRetransFee = 0,
      totalDiscount: oldTotalDiscount = 0,
    },
    saveBillingInfo = false,
    newTotalAmount = 0,
    isRefundDue,
  } = paymentInfo;

  const deliveryFee = newDeliveryFee || oldTotalDeliveryFee;
  const retailDeliveryFee = newRetailDeliveryFee || oldRetailDeliveryFee;
  const serviceFee = newServiceFee || oldServiceFee;
  const retransFee = newRetransFee || oldRetransFee;
  const discount = newDiscount || oldTotalDiscount;
  const taxAmount = newTaxAmount || oldTotalTax;
  const multiProductEligible =
    orderDetailsRes?.orderItems[0]?.multiProductEligible || false;

  orderItems.forEach((orderItem, index) => {
    const {
      deliveryInfo = {},
      productInfo: { updatedLineItems = [], specialInstructions = "" } = {},
    } = orderItem;

    const {
      deliveryDate,
      recipientInfo = {},
      deliveryInstructions = "",
      occasion,
      pickUpBy,
      pickupDate,
      pickUpDateTime,
      cardMessage,
      deliveryMethod = "",
    } = deliveryInfo;

    const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";
    const isMOLOrder = [
      "MOL_FLORIST_DELIVERED",
      "MOL_CUSTOMER_PICKUP",
    ].includes(deliveryMethod);

    let updatedDeliveryInfo = get(
      updatedOrderDetailsRes,
      `orderItems.${index}.deliveryInfo`,
      {}
    );

    const originalDeliveryMethod = updatedDeliveryInfo?.deliveryMethod ?? "";
    const preDeliveryMethod = get(updatedDeliveryInfo, "deliveryMethod", "");

    if (isEmpty(recipientInfo.addressVerificationInfo)) {
      delete recipientInfo.addressVerificationInfo;
    } else {
      if (
        ["FLORIST_PARTNER", "FLORIST_DELIVERED", "PHONE_OUT"].includes(
          deliveryMethod
        )
      ) {
        set(
          updatedOrderDetailsRes,
          `orderItems.${index}.addressVerificationInfo`,
          recipientInfo.addressVerificationInfo
        );
      }

      delete recipientInfo.addressVerificationInfo;
    }

    delete recipientInfo.deliveryMethod;

    let updatedRecipientInfo = get(
      updatedOrderDetailsRes,
      `orderItems.${index}.recipientInfo`,
      {}
    );

    if (
      isEmpty(updatedRecipientInfo) &&
      deliveryMethod === originalDeliveryMethod
    ) {
      delete recipientInfo.firstName;
      delete recipientInfo.lastName;
      delete recipientInfo.addressLine1;
      delete recipientInfo.phone;
      delete recipientInfo.city;
      delete recipientInfo.state;
      delete recipientInfo.zip;
      delete recipientInfo.country;
      delete recipientInfo.locationType;
      delete recipientInfo.locationName;
    } else {
      if (isStorePickupOrder || isWalkInOrder || isMOLOrder) {
        if (!updatedRecipientInfo?.locationType) {
          delete recipientInfo.locationType;
        }

        if (!updatedRecipientInfo?.locationName) {
          delete recipientInfo.locationName;
        }
      }
    }

    if (
      !updatedRecipientInfo?.addressLine2 &&
      isEmpty(recipientInfo.addressLine2)
    ) {
      delete recipientInfo.addressLine2;
    }

    if (!updatedRecipientInfo?.phone && isEmpty(recipientInfo.phone)) {
      delete recipientInfo.phone;
    }

    if (
      !updatedRecipientInfo?.locationType &&
      isEmpty(recipientInfo.locationType)
    ) {
      delete recipientInfo.locationType;
    }

    if (
      !updatedRecipientInfo?.locationName &&
      isEmpty(recipientInfo.locationName)
    ) {
      delete recipientInfo.locationName;
    }

    updatedRecipientInfo = {
      ...updatedRecipientInfo,
      ...recipientInfo,
    };

    if (
      preDeliveryMethod === "FLORIST_DELIVERED" ||
      preDeliveryMethod === "PHONE_OUT" ||
      preDeliveryMethod === "FLORIST_PARTNER"
    ) {
      if (recipientInfo.locationType === "Business")
        updatedRecipientInfo.locationType = "Office";

      if (recipientInfo.locationType === "Funeral Home")
        updatedRecipientInfo.locationType = "Funeral";
    }

    set(
      updatedOrderDetailsRes,
      `orderItems.${index}.recipientInfo`,
      updatedRecipientInfo
    );

    updatedDeliveryInfo = {
      ...updatedDeliveryInfo,
      deliveryDate: isStorePickupOrder
        ? moment(pickupDate, "MM/DD/YY").format("YYYY-MM-DD")
        : deliveryDate,
      deliveryMethod,
      deliveryInstructions,
      occasion,
      cardMessage,
    };

    if (
      ["FLORIST_PARTNER", "FLORIST_DELIVERED"].includes(preDeliveryMethod) &&
      deliveryMethod === "DELIVERY_SERVICE"
    ) {
      updatedDeliveryInfo.deliveryMethod = preDeliveryMethod;
    }

    if (!isEmpty(cardMessage)) {
      if (Platform.OS === "web") {
        const domParser = new DOMParser();
        const htmlDoc = domParser.parseFromString(cardMessage, "text/html");
        const editorContentDoc = htmlDoc.querySelector(
          '[data-contents="true"]'
        );
        const textContent = editorContentDoc && editorContentDoc?.textContent;
        if (textContent) updatedDeliveryInfo.cardMessage = textContent;
      } else {
        const textContent = cardMessage.replace(/<\/?[^>]+(>|$)/g, "");
        if (textContent) updatedDeliveryInfo.cardMessage = textContent;
      }
    }

    if (isStorePickupOrder) {
      let pickUpDateTimeInUTC = moment(pickUpDateTime).utc().format();

      updatedDeliveryInfo.pickUpDateTime = pickUpDateTimeInUTC;
      updatedDeliveryInfo.pickUpBy = pickUpBy;
    }

    if (isWalkInOrder) {
      const memberTimezone = UserProfileStorage.getShopTimeZone(
        values.sendingMember
      );
      set(updatedOrderDetailsRes, `orderItems.${index}.autoForward`, "N");
      updatedDeliveryInfo.deliveryDate = moment
        .tz(memberTimezone)
        .format("YYYY-MM-DD");

      updatedDeliveryInfo.pickUpDateTime = moment().utc().format();
    }

    set(
      updatedOrderDetailsRes,
      `orderItems.${index}.deliveryInfo`,
      updatedDeliveryInfo
    );

    const oldSpecialInstructions = get(
      orderDetailsRes,
      `orderItems.${index}.specialInstructions`
    );

    if (
      oldSpecialInstructions ||
      (!oldSpecialInstructions && !isEmpty(specialInstructions))
    ) {
      set(
        updatedOrderDetailsRes,
        `orderItems.${index}.specialInstructions`,
        specialInstructions
      );
    }

    let totalNoOfItems = updatedLineItems.length;
    let totalQuantity = 0;
    let productDiscount = discount;
    let remainingDiscount = discount;
    let accessoryAmount = 0;
    let accessoryDiscountAmount = 0;
    let itemQuantity = 0;
    let itemOverallPrice = 0;
    let accessories = [];

    let updatedProductLineItems = get(
      updatedOrderDetailsRes,
      `orderItems.${index}.lineItems`,
      []
    );

    updatedLineItems.map((lineItem, idx) => {
      const {
        price,
        newPrice,
        designNotes,
        type,
        quantity = "1",
        isRemoved = false,
      } = lineItem;

      const isProduct = type === "Product";

      itemQuantity = parseInt(quantity);

      let updatedProductLineItem = updatedProductLineItems[idx] || {};

      if (multiProductEligible && isEmpty(updatedProductLineItem)) {
        // Handling newly added product as it won't exists in order already
        updatedProductLineItem = {
          productFirstChoiceCode: lineItem.productFirstChoiceCode,
          productFirstChoiceDescription: lineItem.productFirstChoiceDescription,
          type: type,
          accessories: [],
        };
      }

      if (multiProductEligible || (!multiProductEligible && isProduct)) {
        productDiscount = 0;

        accessories = updatedProductLineItem?.accessories || [];

        itemOverallPrice = parseFloat(
          newPrice || formatPrice(parseFloat(getPrice(price)) * itemQuantity)
        );

        if (totalNoOfItems >= 1 && !isRemoved) {
          if (newDiscount) {
            if (idx === updatedLineItems.length - 1) {
              productDiscount = remainingDiscount;
            } else {
              let productDiscountPercentage =
                (itemOverallPrice / newSubTotalAmount) * 100;

              productDiscount = parseFloat(
                formatPrice((discount * productDiscountPercentage) / 100)
              );
            }

            if (itemOverallPrice < productDiscount) productDiscount = 0;

            updatedProductLineItem = {
              ...updatedProductLineItem,
              discountType: "Dollar",
              discountValue: {
                ["DollarDiscount"]: formatPrice(productDiscount),
              },
            };
          } else {
            const oldLineItemAmounts =
              updatedProductLineItem.lineItemAmounts || [];

            productDiscount = parseFloat(
              oldLineItemAmounts?.find(
                (obj) => obj.name === "productDiscountAmount"
              )?.value || 0
            );

            if (itemOverallPrice < productDiscount) productDiscount = 0;
          }
        }

        remainingDiscount -= productDiscount;

        totalQuantity = itemQuantity;

        updatedProductLineItem.quantity = itemQuantity;
        updatedProductLineItem.totalQuantity = totalQuantity;

        if (isRemoved) updatedProductLineItem.isRemoved = isRemoved;

        const productOldDesignNotes = get(
          orderDetailsRes,
          `orderItems.${index}.lineItems.0.designNotes`
        );

        if (
          productOldDesignNotes ||
          (!productOldDesignNotes && !isEmpty(designNotes.trim()))
        ) {
          updatedProductLineItem.designNotes = designNotes;
        }
      } else {
        const accessoryIndex = idx - 1;

        totalQuantity += itemQuantity;

        let accessoryPrice = parseFloat(
          newPrice || formatPrice(parseFloat(getPrice(price)) * itemQuantity)
        );

        accessoryAmount += parseFloat(formatPrice(accessoryPrice));

        const accDesignNotes = get(
          orderDetailsRes,
          `orderItems.${index}.lineItems.0.accessories.${accessoryIndex}.designNotes`
        );

        let accessory = {
          ...accessories[accessoryIndex],
          accessoryId: lineItem.productFirstChoiceCode,
          accessoryDesc: lineItem.productFirstChoiceDescription,
          accessoryQty: 1,
          accessoryPrice: parseFloat(accessoryPrice),
          ...(accDesignNotes || (!accDesignNotes && !isEmpty(designNotes))
            ? { designNotes: designNotes }
            : {}),
        };

        let accessoryDiscount = 0;

        if (newDiscount) {
          if (idx === updatedLineItems.length - 1) {
            accessoryDiscount = remainingDiscount;
          } else {
            let accessoryDiscountPercentage =
              (accessoryPrice / newSubTotalAmount) * 100;

            accessoryDiscount = parseFloat(
              formatPrice((discount * accessoryDiscountPercentage) / 100)
            );
            remainingDiscount -= accessoryDiscount;
          }

          if (accessoryPrice < accessoryDiscount) accessoryDiscount = 0;

          accessoryDiscountAmount += parseFloat(formatPrice(accessoryDiscount));

          accessory = {
            ...accessory,
            discountedPrice: formatPrice(accessoryPrice - accessoryDiscount),
            discountType: "Dollar",
            discountValue: {
              ["DollarDiscount"]: formatPrice(accessoryDiscount),
            },
          };
        } else {
          const key =
            accessory.discountType === "Percentage"
              ? "PercentDiscount"
              : "DollarDiscount";

          const discountValue = accessory.discountValue?.[key] || 0;

          accessoryDiscount = getDiscount(
            discountValue,
            accessoryPrice,
            accessory.discountType,
            quantity
          );

          if (accessoryPrice < accessoryDiscount) accessoryDiscount = 0;

          accessoryDiscountAmount += parseFloat(formatPrice(accessoryDiscount));

          accessory = {
            ...accessory,
            ...(!isMOLOrder && {
              discountedPrice: formatPrice(accessoryPrice - accessoryDiscount),
            }),
          };
        }

        accessories[accessoryIndex] = accessory;
      }

      if (!multiProductEligible && idx === updatedLineItems.length - 1) {
        updatedProductLineItems[0] = {
          ...updatedProductLineItems[0],
          accessories,
          totalQuantity,
        };
      }

      if (isRefundDue !== undefined && !isRemoved) {
        const discountedProductAmount =
          parseFloat(itemOverallPrice) - parseFloat(productDiscount);

        const discountedAccessoryAmount =
          parseFloat(accessoryAmount) - parseFloat(accessoryDiscountAmount);

        const lineItemAmounts = [
          {
            name: "retailProductAmount",
            value: formatPrice(itemOverallPrice),
          },
          {
            name: "saleProductAmount",
            value: formatPrice(itemOverallPrice),
          },
          {
            name: "discountedProductAmount",
            value: formatPrice(itemOverallPrice - productDiscount),
          },
          {
            name: "accessoryAmount",
            value: formatPrice(accessoryAmount),
          },
          {
            name: "discountedAccessoryAmount",
            value: formatPrice(accessoryAmount - accessoryDiscountAmount),
          },
          {
            name: "taxAmount",
            value: "0.00",
          },
          {
            name: "discountAmount",
            value: formatPrice(
              multiProductEligible
                ? productDiscount
                : productDiscount + accessoryDiscountAmount
            ),
          },
          {
            name: "productDiscountAmount",
            value: formatPrice(productDiscount),
          },
          {
            name: "accessoryDiscountAmount",
            value: formatPrice(accessoryDiscountAmount),
          },
          {
            name: "savings",
            value: "0.00",
          },
          {
            name: "amountChargedToCustomer",
            value: formatPrice(
              discountedProductAmount + discountedAccessoryAmount
            ),
          },
        ];

        if (
          !multiProductEligible &&
          idx === updatedLineItems.length - 1 &&
          totalNoOfItems > 1
        ) {
          updatedProductLineItems[0] = {
            ...updatedProductLineItems[0],
            lineItemAmounts,
          };
        } else {
          updatedProductLineItem = {
            ...updatedProductLineItem,
            lineItemAmounts,
          };
        }
      }

      if (multiProductEligible || (!multiProductEligible && isProduct)) {
        set(
          updatedOrderDetailsRes,
          `orderItems.${index}.lineItems.${idx}`,
          updatedProductLineItem
        );
      }
    });

    // Removing items from updatedOrderDetailsRes which are removed before preparing patch by taking latest lineItems
    updatedProductLineItems = get(
      updatedOrderDetailsRes,
      `orderItems.${index}.lineItems`,
      []
    );

    updatedProductLineItems = updatedProductLineItems.filter(
      (lineItem) => !lineItem.isRemoved
    );

    set(
      updatedOrderDetailsRes,
      `orderItems.${index}.lineItems`,
      updatedProductLineItems
    );

    if (isRefundDue !== undefined) {
      const oldSurCharges = get(
        updatedOrderDetailsRes,
        `orderItems.${index}.fees.applicableCharges.surCharges`,
        []
      );

      const fees = {
        applicableCharges: {
          surCharges:
            Number(retailDeliveryFee) > 0
              ? [
                  {
                    name: "retailDeliveryFee",
                    type: "Additional",
                    feeType: "SC",
                    value: parseFloat(retailDeliveryFee),
                    valueType: "FIXED",
                    promotionId: 1,
                    adjustmentId: 1,
                    adjustmentCode: "String",
                    category: "String",
                  },
                ]
              : oldSurCharges,
          deliveryCharges: [
            {
              name: "vendor",
              type: "Standard",
              feeType: "DC",
              value: parseFloat(deliveryFee),
              valueType: "FIXED",
              promotionId: 1,
              adjustmentId: 1,
              adjustmentCode: "String",
            },
            {
              name: "morning",
              type: "Additional",
              feeType: "DC",
              value: 0.0,
              valueType: "FIXED",
              promotionId: 1,
              adjustmentId: 1,
              adjustmentCode: "String",
            },
          ],
          totalApplicableCharges: formatPrice(
            parseFloat(deliveryFee) +
              parseFloat(retailDeliveryFee) +
              parseFloat(relayFee) +
              parseFloat(serviceFee) +
              parseFloat(retransFee)
          ),
        },
        discountedCharges: {
          surCharges: [],
          deliveryCharges: [],
          totalDiscountedCharges: 0.0,
        },
      };

      set(updatedOrderDetailsRes, `orderItems.${index}.fees`, fees);

      const taxAmounts =
        newTaxSplits?.map((taxInfo) => {
          let newObj = {};
          for (let prop of Object.keys(taxInfo)) {
            newObj = {
              ...newObj,
              [camelCase(`tax ${prop}`)]: taxInfo[prop],
            };
          }
          return newObj;
        }) || [];

      set(updatedOrderDetailsRes, `orderItems.${index}.taxAmounts`, taxAmounts);

      const totalTax = { amount: parseFloat(newTaxAmount), rate: newTaxRate };
      set(updatedOrderDetailsRes, `orderItems.${index}.totalTax`, totalTax);

      let updatedPrice = [
        {
          name: "orderTotal",
          value: formatPrice(
            parseFloat(newSubTotalAmount) + parseFloat(deliveryFee)
          ),
        },
        {
          name: "amountChargedToCustomer",
          value: amountChargedToCustomer,
        },
      ];

      set(updatedOrderDetailsRes, `orderItems.${index}.price`, updatedPrice);
    }
  });

  set(updatedOrderDetailsRes, "promoCode", orderItems[0]?.promoCode);

  const hasSettlementError = get(
    updatedOrderDetailsRes,
    `orderItems.0.hasSettlementError`,
    false
  );

  if (isRefundDue !== undefined) {
    let updatedOrderAmounts = {
      orderTotal: formatPrice(
        parseFloat(newSubTotalAmount) + parseFloat(deliveryFee)
      ),
      discountAmount: formatPrice(discount),
      savings: "0.00",
      amountChargedToCustomer,
      taxAmount: formatPrice(taxAmount),
      feeTotal: parseFloat(
        Number(deliveryFee) + Number(retailDeliveryFee)
      ).toFixed(2),
      feeDiscountTotal: "0",
      feeSummary: `{"standardDelivery":${formatPrice(deliveryFee)},${
        Number(retailDeliveryFee) > 0
          ? `"retailDeliveryFee":${parseFloat(retailDeliveryFee)},`
          : `"retailDeliveryFee":0,`
      }"relayFee":0,"morning":0}`,
    };

    const mergeOrderAmounts = updatedOrderDetailsRes.orderAmounts.map(
      (item) => {
        if (updatedOrderAmounts[item.name]) {
          item.value = updatedOrderAmounts[item.name];
        }
        return item;
      }
    );

    set(updatedOrderDetailsRes, `orderAmounts`, mergeOrderAmounts);

    if (!isRefundDue && parseFloat(newTotalAmount) > 0) {
      let updatedPaymentMethods = get(
        updatedOrderDetailsRes,
        `paymentDetails.paymentMethod`,
        []
      );

      const {
        paymentMethodDetails,
        paymentMethodType,
        billingInformation = {},
      } = getPaymentInfo(values, tokenId);

      let newPaymentMethod = {
        paymentMethodType:
          paymentMethodType === "SAVED_CARD"
            ? "CREDIT_CARD"
            : paymentMethodType,
        enablePayment: true,
        savePayment: saveBillingInfo,
        paymentMethodDetails,
        billingInformation,
      };

      updatedPaymentMethods.push(newPaymentMethod);

      set(
        updatedOrderDetailsRes,
        `paymentDetails.paymentMethod`,
        updatedPaymentMethods
      );
    }
  } else if (hasSettlementError && parseFloat(newTotalAmount) > 0) {
    let updatedPaymentMethods = get(
      updatedOrderDetailsRes,
      `paymentDetails.paymentMethod`,
      []
    );

    const {
      paymentMethodDetails,
      paymentMethodType,
      billingInformation = {},
    } = getPaymentInfo(values, tokenId);

    let newPaymentMethod = {
      paymentMethodType: ["SAVED_CARD", "PAYMENT_TERMINAL"].includes(
        paymentMethodType
      )
        ? "CREDIT_CARD"
        : paymentMethodType,
      enablePayment: true,
      savePayment: saveBillingInfo,
      paymentMethodDetails,
      billingInformation,
    };

    if (paymentMethodType === "PAYMENT_TERMINAL") {
      const merchantReferenceId = get(
        updatedOrderDetailsRes,
        "paymentDetails.paymentMethod.0.merchantReferenceId",
        ""
      );

      newPaymentMethod = {
        ...newPaymentMethod,
        isHardwareTerminal: true,
        merchantReferenceId,
      };
    }

    updatedPaymentMethods.push(newPaymentMethod);

    set(
      updatedOrderDetailsRes,
      `paymentDetails.paymentMethod`,
      updatedPaymentMethods
    );
  }

  if (!isEmpty(customerInfo)) {
    const {
      customerId,
      customerType,
      firstName,
      lastName,
      businessName,
      phone,
      email,
      customerNotes,
    } = customerInfo;

    let updatedCustomerInfo = get(updatedOrderDetailsRes, "customerInfo", {});

    updatedCustomerInfo = {
      ...updatedCustomerInfo,
      customerId,
      isBusinessProfile: customerType === "Business",
      firstName,
      lastName,
      businessName,
      email,
      customerNotes,
      ...(!isEmpty(phone) || !isEmpty(updatedCustomerInfo?.phones)
        ? { phones: [formatPhoneForPayload(phone)] }
        : {}),
    };

    if (customerType === "Business") {
      delete updatedCustomerInfo.firstName;
      delete updatedCustomerInfo.lastName;
    } else {
      delete updatedCustomerInfo.businessName;
    }

    set(updatedOrderDetailsRes, `customerInfo`, updatedCustomerInfo);
  }

  var orderUpdatePatches = generateJSONPatch(
    orderDetailsRes,
    updatedOrderDetailsRes
  );

  return orderUpdatePatches;
};

export const getOrderDetailsTabs = (
  orderDetailResponse = {},
  isFloristPickup,
  isWalkInOrder,
  isPickUpOrder,
  showPayment
) => {
  const {
    hasSettlementError = false,
    hasFulfillmentError = false,
    hasDSFulfillmentError = false,
  } = get(orderDetailResponse, "orderItems.0", {});

  const paymentStatus =
    orderDetailResponse.paymentDetails?.paymentMethod[0]?.paymentStatus || "";

  const lineItems = orderDetailResponse.orderItems[0].lineItems;

  const isGiftCardDigitalProduct = digitalGiftCardProducts.includes(
    lineItems[0]?.productFirstChoiceCode
  );

  const orderStatus = orderDetailResponse.orderItems[0].status || "";
  const showPaymentError =
    orderStatus !== "CANCELLED" && paymentStatus?.toLowerCase() === "pending";

  const tabs = [
    {
      key: "order-summary",
      title: "Order Details",
      error: hasFulfillmentError,
    },
    {
      key: "delivery",
      title: isWalkInOrder
        ? isGiftCardDigitalProduct
          ? "Digital Info"
          : "Walk-In Info"
        : isFloristPickup || isPickUpOrder
        ? "Pickup Info"
        : "Delivery",
      error: hasDSFulfillmentError,
    },
    { key: "order-journey", title: "Order Journey" },
  ];

  // show payment tab based on showPayment flag
  if (showPayment)
    tabs.push({
      key: "payment",
      title: "Payment",
      error: hasSettlementError || showPaymentError,
    });

  return tabs;
};

// This helper function used to generate the content for the order details layout. It has support for label & without label.
export const generateDetailContent = (
  data,
  suppressNull = false,
  valueText = {}
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { messages, Localise } = React.useContext(I18NContext);
  return Object.keys(data).map((key) => {
    const input = data[key] || "";
    const { label = "", value = "", valueStyle = {}, labelStyle = {} } = input;

    if ((isEmpty(input) || isEmpty(value)) && suppressNull) return;
    return (
      <View
        style={{ ...tw("flex flex-row"), zIndex: -1 }}
        key={key}
        fsClass={
          [("Phone", "Recipient Phone", "Email")].includes(label) ||
          ["Phone", "Recipient Phone", "Email"].includes(key)
            ? "fs-exclude"
            : "fs-unmask"
        }
        testID={key}
      >
        {!!label && (
          <Text
            style={[
              fonts.style1,
              { fontSize: 14, paddingVertical: 2, paddingRight: 5 },
              labelStyle,
            ]}
          >
            {Localise(messages, label)}:
          </Text>
        )}
        {typeof value === "object" ? (
          <View>
            {Object.keys(value).map((key) => (
              <Text style={tw("pb-1")} key={`${key}-value`}>
                {value[key]}
              </Text>
            ))}
          </View>
        ) : (
          <Text
            style={{
              ...valueText,
              ...(valueStyle.isNegative ? { color: colors.red } : {}),
              ...fonts.style1,
              fontSize: 14,
              paddingVertical: 2,
            }}
          >
            {valueStyle.isPrice
              ? `$${
                  valueStyle.isNegative
                    ? `(${formatPrice(value)})`
                    : formatPrice(value)
                }`
              : value}
          </Text>
        )}
      </View>
    );
  });
};

export const isAdddressChangedFn = (
  formikValues,
  formikInitialValues,
  formIKPath
) => {
  const {
    addressLine1: newAddressLine1 = "",
    addressLine2: newAddressLine2 = "",
    city: newCity = "",
    country: newCountry = "",
    state: newState = "",
    zip: newZip = "",
  } = get(formikValues, formIKPath, {});

  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    country = "",
    state = "",
    zip = "",
  } = get(formikInitialValues, formIKPath, {});

  const isAddressChanged = !isEqual(
    { addressLine1, addressLine2, city, country, state, zip },
    {
      addressLine1: newAddressLine1,
      addressLine2: newAddressLine2,
      city: newCity,
      country: newCountry,
      state: newState,
      zip: newZip,
    }
  );
  return isAddressChanged;
};

export const qrPlaceHolder = (qrImageData, styleObj = {}) => {
  const { width: qrWidth, height: qrHeight, position = "" } = styleObj;
  const width = qrWidth || 100;
  const height = qrHeight || 100;
  return `${
    qrImageData
      ? `<div style="margin-top:5px; ${
          position !== ""
            ? `text-align:${position === "bottom" ? "right" : "left"}`
            : ""
        }" data-testid="qrImage_container">    
                        <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="${width}px"
                        height="${height}px"
                        viewBox="0 0 ${width} ${height}"
                        enable-background="new 0 0 ${width} ${height}"
                        xml:space="preserve"
                        >               
                           <image
                              id="image0"
                              width="${width}"
                              height="${height}"
                              x="0"
                              y="0"
                              href="${qrImageData}"
                           />
                        </svg>
                     </div>`
      : ``
  }`;
};
