const SHOP_SETTINGS = Object.freeze({
  ADDON_SETTINGS_SAVED: "Your addon settings are saved",
  AUTO_SEND_EMAIL: "Auto Send Email",
  AUTO_SEND_INVOICE: "Auto Send Invoice",
  AUTO_SEND_SMS: "Auto Send SMS",
  CODE: "Code",
  CODES: "Codes",
  EMAIL_HA_INVOICE_TEXT:
    "Turning on this notification will trigger an automated email to your House Account Customers when a new order is generated. If you don't want to send to specific customer, you can update from customer directory",
  EMAIL_INVOICE_TEXT:
    "Turning on this notification will allow you to send Invoice to customers when requested from Order listing",
  NO_PROMO_CODES: "No Promo Codes available. Please create one.",
  PROMO_CODE_ACTIVATED: "Promo Code has been activated",
  PROMO_CODE_DEACTIVATED: "Promo Code has been deactivated",
  PROMO_CODE_INVALID: "Code must be between 2 to 15 characters long",
  PROMO_CODE_REQUIRED: "Code is required",
  PROMO_CODES: "Promo Codes",
  PROMO_CONFIRM_MODAL_CONTENT: "Do you want to delete the Promo code?",
  PROMO_CREATION_SUCCESS: "Promo Code has been created successfully",
  PROMO_DELETION_SUCCESS: "Promo Code has been deleted successfully",
  PROMO_DUPLICATE: "Promo Code exists with same date range",
  PROMO_HEADING: "Create Promo Code",
  PROMO_INFO_TOOLTIP:
    "Enter an alphanumeric code, up to 15 characters. Special characters are not allowed.",
  PROMO_TITLE: "Promo Code",
  PROMO_UPDATE_SUCCESS: "PromoCode has been updated successfully",
  PROMO_ZERO_AMOUNT:
    "Promo code value cannot be zero. Please enter a valid amount.",
});

export default SHOP_SETTINGS;
