import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { Platform } from "react-native";

import IMAGES from "static/assets/images";

import {
  formatPrice,
  phoneNumberFormatter,
  formatCardMessage,
} from "library/utils/formatter";
import {
  getDiscount,
  deliveryMethodsKeyToValue,
} from "library/utils/createOrder";
import {
  qrPlaceHolder,
  keyMap,
} from "components/views/drawer/order-details/helper.js";
import UserProfileStorage from "library/storage/userProfile";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import {
  extractTimeFromDI,
  DELIVER_BY_REGEX,
} from "components/views/drawer/create-order/helper";

const getWorksheetCSS = ({ hasLocationName }) => {
  return `.cardMessage {
        word-break: break-word;
        white-space: pre-wrap;
      }
      .ql-font-arial {
        font-family: "Arial";
      }
      .ql-font-georgia {
        font-family: "Georgia";
      }
      .ql-font-verdana {
        font-family: "Verdana";
      }
      .ql-font-impact {
        font-family: "Impact";
      }
      .ql-font-tahoma {
        font-family: "Tahoma";
      }
      .ql-font-times {
        font-family: "Times New Roman", Times, serif;
      }
      .ql-align-left {
        text-align: left;
      }
      .ql-align-right {
        text-align: right;
      }
      .ql-align-center {
        text-align: center;
      }
      .ql-align-justify {
        text-align: justify;
      }
      .page-header,
      .page-header-space {
        height: ${hasLocationName ? "230px" : "222px"};
      }
      .page-footer,
      .page-footer-space {
        height: ${hasLocationName ? "255px" : "240px"};
      }
      .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
      .page-header {
        position: fixed;
        top: 0;
        width: 100%;
      }
      .head-container {
        display: flex;
        justify-content: space-between;
        height: ${hasLocationName ? "205px" : "187px"};
        width: 100%;
        flex: 1;
      }
      .foot-container {
        height: ${hasLocationName ? "255px" : "240px"};
        overflow: hidden;
      }
      .header-title {
        font-size: 20px;
      }
      .footer-title {
        font-size: 18px;
      }
      .table-responsive td:first-child {
        text-align: left;
        width: 3%;
      }
      .table-responsive td:nth-child(2) {
        text-align: left;
        width: 53.5%;
      }
      .table-responsive td:nth-child(3) {
        text-align: right;
        width: 6%;
        word-break: break-word;
      }
      .table-responsive td:nth-child(4) {
        text-align: right;
        width: 12.5%;
        word-break: break-word;
      }
      .table-responsive td:nth-child(5) {
        text-align: right;
        width: 12.5%;
        word-break: break-word;
      }
      .table-responsive td:nth-child(6) {
        text-align: right;
        width: 12.5%;
      }
      .tableHeader {
        text-align: left;
      }
      @page {
        margin: 20px;
        font-size: 12px;
        -webkit-margin-before: 20px;
        -webkit-margin-after: 10px;
      }
      .page-break-flow-root {
        display: flow-root;
        page-break-after: always;
      }
      .editorContent {
        p,
        ol,
        ul,
        pre,
        blockquote,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0px;
          padding: 0px;
        }
      }
      @media print {
        thead {
          display: table-header-group;
        }
        tfoot {
          display: table-footer-group;
        }
        html,
        body {
          height: auto;
          -ms-overflow-style: none;
          margin: 0;
          font-size: 12px;
          font-family: Helvetica, sans-serif;
        }
        .table tbody > tr > td,
        .table > tbody > tr > th,
        .table > tfoot > tr > td,
        .table > tfoot > tr > th,
        .table > thead > tr > td,
        .table > thead > tr > th {
          padding: 5px;
          line-height: 1.3;
          vertical-align: top;
        }
        .table {
          border-collapse: collapse;
          width: 100%;
          max-width: 100%;
          margin-bottom: 10px;
          border-spacing: 0px;
        }
        .recipe-table tbody > tr > td,
        .recipe-table > tbody > tr > th,
        .recipe-table > tfoot > tr > td,
        .recipe-table > tfoot > tr > th,
        .recipe-table > thead > tr > td,
        .recipe-table > thead > tr > th {
          padding: 0;
          line-height: auto;
          vertical-align: top;
          font-size: 12px;
        }
      }`;
};

export const getSingleWorksheetPrintHTML = ({
  orderDetails,
  operatorName,
  messages = {},
  Localise = () => {},
  selectedproducts = [],
  customerInfo = {},
  paymentDetails = {},
  zoneDetails = [],
  qrImageData = "",
  isSinglePrint = true,
}) => {
  const {
    deliveryInfo = {},
    pickupInfo = {},
    lineItems = [],
    multiProductEligible = false,
    messageNumber = "",
    price,
    totalTax = {},
    recipientInfo,
    receivingMember = {},
    sendingMember = {},
    createdOn,
    fees = {},
    orderSource = "",
    isRushOrder = false,
    orderAmounts = [],
    hasPendingPayLaterPayment = false,
    giftCardType = "",
    direction = {},
  } = orderDetails;

  let floristShopContactSettings = "";

  const deliveryZoneName =
    zoneDetails.length > 0
      ? zoneDetails[0].name
      : deliveryInfo?.deliveryZoneName || "";

  const shopPreferences = UserProfileStorage.getShopPreferences(
    receivingMember.memberCode
  );

  let isNonCoreEntitlement = isMHQNonCoreMember(receivingMember.memberCode);
  let isShopNameOverridden = false;

  floristShopContactSettings = get(
    shopPreferences,
    "florist_contact_information_settings",
    ""
  );

  if (floristShopContactSettings == "JDE" || floristShopContactSettings == "") {
    floristShopContactSettings =
      '{"shopName":"","streetAddress":"","aptSuite":"","city":"","state":"","zip":"","phone":"","email":"","website":"","additionalMessage":"","contactName":"","printed":[],"web":[],"network":[]}';
  }

  let { shopName = "", printed = [] } = JSON.parse(floristShopContactSettings);

  if (printed.includes("shopName") && shopName != "") {
    isShopNameOverridden = true;
  }

  const { paymentMethodType = "" } = get(paymentDetails, "paymentMethod.0", {});

  const {
    cardMessage,
    deliveryDate,
    occasion,
    deliveryMethod = "",
    deliveryInstructions = "",
    orderDeliveryTime = "",
    pickUpBy = "",
  } = deliveryInfo;

  const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";

  const deliverByTime =
    orderDeliveryTime ||
    (isRushOrder && extractTimeFromDI(deliveryInstructions)); //For old rush orders before timed delivery feature

  let formattedDeliveryInstructions =
    deliveryInstructions.length > 0
      ? deliveryInstructions.split("\\n").join("\n")
      : deliveryInstructions;

  if (deliverByTime) {
    formattedDeliveryInstructions = deliveryInstructions.replace(
      DELIVER_BY_REGEX,
      ""
    );
  }

  formattedDeliveryInstructions =
    formattedDeliveryInstructions.length > 150
      ? formattedDeliveryInstructions.substring(0, 150) + "..."
      : formattedDeliveryInstructions;

  const { storePickupDateTime = "" } = pickupInfo || {};

  const pickOrDeliverByTime =
    deliverByTime ||
    (storePickupDateTime ? moment(storePickupDateTime).format("h:mm A") : "");

  const { accessories: oldAccessories } = lineItems[0];

  const formattedDeliveryMethod =
    giftCardType === "DIGITAL_GIFT_CARDS"
      ? "Digital"
      : hasPendingPayLaterPayment
      ? `${deliveryMethodsKeyToValue[deliveryMethod]} - Unpaid`
      : deliveryMethod === "FLORIST_PARTNER" && direction === "INBOUND"
      ? "Florist Delivered"
      : deliveryMethodsKeyToValue[deliveryMethod] || "";

  const accessories = multiProductEligible
    ? lineItems.filter((lineItem) => lineItem.type === "Addon")
    : oldAccessories;

  const products = multiProductEligible
    ? lineItems.filter((lineItem) => lineItem.type === "Product")
    : lineItems;

  let productsInfo = {};
  products.map((each) => {
    const productId = each.productFirstChoiceCode?.toLowerCase() || "";
    const currentproduct = selectedproducts.find((prod) =>
      [prod.refNumberId?.toLowerCase(), prod.productId?.toLowerCase()].includes(
        productId
      )
    );

    if (currentproduct) {
      let product = cloneDeep(currentproduct);
      productsInfo = {
        ...productsInfo,
        [product.productId.toLowerCase()]: product,
      };
    }
  });

  const {
    addressLine1: delAddress = "",
    addressLine2: delAddress2 = "",
    city: delCity = "",
    firstName = "",
    lastName = "",
    state: delState = "",
    zip: delZip = "",
    locationName = "",
    phone: delPhone = "",
  } = recipientInfo;

  const {
    firstName: custFirstName = "",
    lastName: custLastName = "",
    businessName = "",
    customerType = "",
    isBusinessProfile = false,
    phones = [""],
    email = "",
    customerContacts = [],
  } = customerInfo;

  const selectedContact = customerContacts?.find(
    (contact) => contact.isSelected
  );

  const isBusinessAccount = isBusinessProfile || customerType === "Business";
  const customerName = isBusinessAccount
    ? businessName
    : `${custFirstName} ${custLastName}`.trim();

  const hasCustomerInfo = customerName.trim().length > 0;
  const hasRecipientInfo = firstName.length > 0 || lastName.length > 0;
  const delName = firstName + " " + lastName;
  const hasLocationName = hasRecipientInfo && locationName;
  let { businessName: sendBusinessName = "" } = sendingMember;

  if (isSinglePrint) {
    sendBusinessName = isNonCoreEntitlement
      ? isShopNameOverridden
        ? shopName
        : sendBusinessName
      : sendBusinessName;
  }

  let accessoriesDescription = "";
  accessories.map((accessory) => {
    accessoriesDescription = `${accessoriesDescription} PRICE INCLUDES 1 ${
      accessory?.accessoryId || ""
    }-${accessory?.accessoryDesc}($${accessory?.accessoryPrice}), ${
      accessory?.designNotes ? `Design notes: ${accessory.designNotes}` : ""
    }   `;
  });

  const cityStateAndZip = `${
    delCity && delState ? `${delCity}, ${delState}` : delCity || delState
  } ${delZip}`;

  const totalProducts = [...products, ...accessories];

  const deliveryFee =
    get(fees, "applicableCharges.deliveryCharges", []).find(
      (obj) => obj.type === "Standard"
    )?.value || 0;

  const retailDeliveryFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retailDeliveryFee"
    )?.value || 0;

  const relayFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "relayFee"
    )?.value || 0;

  const mhqServiceFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "serviceFee"
    )?.value || 0;

  const retransFee =
    get(fees, "applicableCharges.surCharges", []).find(
      (obj) => obj.name === "retransFee"
    )?.value || 0;

  const hasRetailDeliveryFee = Number(retailDeliveryFee) > 0;
  const hasRelayFee = Number(relayFee) > 0;

  const feeSummary =
    orderAmounts?.find((obj) => obj.name === "feeSummary")?.value || "{}";
  const parseSummary = JSON.parse(feeSummary);
  const serviceFee = parseSummary.fuelSurcharge || 0;
  const rushDeliveryFee = parseSummary.rushDeliveryFee || 0;

  const isMOLOrder = orderSource === "MOL";
  const totalDeliveryFee =
    isRushOrder && rushDeliveryFee > 0 && deliveryFee > 0
      ? deliveryFee - rushDeliveryFee
      : deliveryFee;

  let subTotal = 0;

  const isEmailOrder = orderSource === "Email Order";

  const hasMHQServiceFee = isEmailOrder && Number(mhqServiceFee) > 0;
  const hasRetransFee = isEmailOrder && Number(retransFee) > 0;

  let productCount = 0;
  const getProductRecipeLength = () => {
    const productListData = totalProducts.map(
      (prod) => productsInfo[prod?.productFirstChoiceCode?.toLowerCase()]
    );
    productListData.map((p) => {
      if (p?.recipeList?.length) {
        productCount++;
      }
    });
    return productCount;
  };

  const header = () => {
    return `<div style="display:flex; flex-direction:column; flex: 1">
              <div style="display:flex; flex-direction:row; justify-content:space-between;">
                <div style="display:flex; flex-direction: column;">
                  <div style="font-weight:bold; font-size: 16px !important;"> ${Localise(
                    messages,
                    "Order No"
                  )}: ${messageNumber} </div>
                  <div style="min-height: 75px;">${qrPlaceHolder(qrImageData, {
                    width: 75,
                    height: 75,
                    position: "top",
                  })}</div>
                </div>
                  <div style="font-weight:bold !important;text-align: center; ${
                    isSinglePrint ? "width: 40%;" : ""
                  }">
                  <div class="worksheet-title" style="font-size:22px;">${Localise(
                    messages,
                    "Worksheet"
                  )}</div>
                  <div style="font-size:12px;" data-testid="shopName_header">${sendBusinessName}</div>
                  </div>
                  <div>
                  <div style="text-align:right;font-weight:bold;font-size:16px !important;">${Localise(
                    messages,
                    isStorePickupOrder ? "Pickup Date" : "Delivery Date"
                  )}: ${moment(deliveryDate).format("ddd MM/DD/YYYY")}</div>
                  ${
                    pickOrDeliverByTime
                      ? `<div style="text-align:right;font-weight:bold;font-size:16px !important" data-testid="deliveryTime_header">${Localise(
                          messages,
                          isStorePickupOrder ? "Pickup Time" : "Delivery Time"
                        )}: ${pickOrDeliverByTime}</div>`
                      : ``
                  }
                  <span id="pageNumber" style="text-align:right;font-weight:bold; color:red; font-size: 12px !important;"></span>
                  </div>
              </div>
          
          <div class="worksheet-header" style="font-size:13px !important; display:flex; flex-direction:row;justify-content: space-between; padding-top: 5px; padding-bottom: 10px;">
            ${
              hasRecipientInfo
                ? `<div style="width:34%;text-align:left;font-size: 14px;">
                <div style="padding: 1px 0;font-weight:bold !important">${Localise(
                  messages,
                  "Deliver To"
                )}</div>
                <div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${delName}</div>
                ${
                  recipientInfo.locationName
                    ? `<div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
                    : ``
                }
                <div style="padding: 1px 0;">
                    <div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${delAddress}${
                    delAddress2 ? `, ${delAddress2}` : ``
                  }</div>
                    <div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${cityStateAndZip}</div>
                    <div>${phoneNumberFormatter(delPhone)}</div>
                </div>
                </div>`
                : `<div style="width:34%;text-align:left;font-size: 14px;"></div>`
            }     
            ${
              hasCustomerInfo
                ? `<div style="width:32%; text-align:left; padding-right: 5px;font-size: 14px;">
                    <div style="font-weight:bold !important; paddding: 1px 0;">${Localise(
                      messages,
                      "Customer"
                    )}</div>
                    <div style="padding: 1px 0;">${customerName}</div>
                    ${
                      selectedContact
                        ? `<div style="padding: 1px 0;">${
                            selectedContact.firstName
                          }${
                            selectedContact.lastName
                              ? ` ${selectedContact.lastName}`
                              : ""
                          }</div>`
                        : ""
                    }
                    <div style="padding: 1px 0;">${phoneNumberFormatter(
                      selectedContact?.phone || phones[0] || ""
                    )}</div>
                    <div style="word-break: break-word;padding: 1px 0;">${
                      selectedContact?.email || email || ""
                    }</div>
                    </div>`
                : `<div style="width:32%; text-align:left; padding-right: 5px;font-size: 14px;"></div>`
            } 
                  <div style="width:30%">
                  ${
                    paymentMethodType !== ""
                      ? `<div style="display: flex; flex-direction: row; justify-content: space-between; padding: 1px 0;">
                      <span style="font-weight:bold !important">${Localise(
                        messages,
                        "Payment"
                      )}</span>
                      <span>${keyMap[paymentMethodType]}</span>
                  </div>`
                      : ``
                  }
                  <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 1px 0;">
                      <span style="font-weight:bold !important">${Localise(
                        messages,
                        "Salesperson"
                      )}</span>
                      <span>${operatorName}</span>
                  </div>
                  <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 1px 0;">
                      <span style="font-weight:bold !important">${Localise(
                        messages,
                        "Occasion"
                      )}</span>
                      <span style="text-transform: capitalize;">${startCase(
                        toLower(occasion)
                      )}</span>
                  </div>
                  <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 1px 0;">
                      <span style="font-weight:bold !important">${Localise(
                        messages,
                        "Sale Date"
                      )}</span>
                      <span>${moment(createdOn).format(
                        "MM/DD/YYYY hh:mm A "
                      )}</span>
                  </div>
                  </div>
              
          </div>
         
      </div>`;
  };

  const getProductNames = () => {
    let otherQty = 0;
    const content = totalProducts.map((each, index) => {
      if (index < 7) {
        return `<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"> - ${
          each.productFirstChoiceDescription ||
          each.accessoryDesc ||
          each.productFirstChoiceCode
        }</div> `;
      } else {
        otherQty += 1;
        return "";
      }
    });

    if (otherQty > 0) {
      return `${content.join("")}<div> - ${otherQty} Others</div>`;
    } else {
      return content.join("");
    }
  };

  const checkBoxPlaceholder = () =>
    `<img src="${IMAGES["checkbox-unchecked-dark"]}" width="13" height="13">`;

  const getSingleRecipeContent = (productFirstChoiceCode) => {
    let recipeContent = "";

    const { recipeList = [] } =
      productsInfo[productFirstChoiceCode?.toLowerCase()] || {};

    recipeContent =
      recipeContent +
      recipeList
        .map((recipeItem, idx) => {
          if (idx === 0) {
            return ``;
          } else {
            return `<tr style="font-size:12px !important;">
                <td style="display:flex; width: 100% !important;">
                  <span style="flex:1; min-width: 50px;">${
                    recipeItem.quantity
                  }</span>
                  <span style="flex:1; min-width: 70px;">${
                    recipeItem.color || "-"
                  }</span>
                  <span style="flex:4; min-width: 200px;">${
                    recipeItem.item || "-"
                  }</span>
                </td>
              </tr>`;
          }
        })
        .join("");

    return recipeContent;
  };

  const getMultipleRecipeContent = (recipe) => {
    let recipeContent = "";
    let recipeList = [];
    if (recipe.length) {
      try {
        recipeList = JSON.parse(recipe);
      } catch (e) {
        try {
          recipe.split(",").map((ele) => {
            const [f1, ...other] = ele.trim().split(" ");
            recipeList.push({
              quantity: f1,
              item: other.join(" "),
            });
          });
        } catch (e) {
          console.log("Failed to parse recipe info");
        }
      }
    }

    recipeContent =
      recipeContent +
      recipeList
        .map((recipeItem, idx) => {
          return `<tr style="font-size:12px !important;">
                <td style="display:flex; width: 100% !important;">
                  <span style="flex:1; min-width: 50px;">${
                    recipeItem.quantity
                  }</span>
                  <span style="flex:1; min-width: 70px;">${
                    recipeItem.color || "-"
                  }</span>
                  <span style="flex:4; min-width: 200px;">${
                    recipeItem.item || "-"
                  }</span>
                </td>
              </tr>`;
        })
        .join("");

    return recipeContent;
  };

  const footer = () => {
    return `<div class="table-responsive bottom-section" style="display:flex; flex-direction:row; font-size:16px !important; border-top: 1px dashed #666; padding-top: 10px;">
                <div class="left-col" style="font-weight:bold; width:50%;">
                  <div style="padding-bottom: 2px">
                    <span>${Localise(
                      messages,
                      "Order No"
                    )}:</span> ${messageNumber}
                  </div>
                  <div style="padding-bottom: 2px">
                    <span>${Localise(
                      messages,
                      "Delivery Method"
                    )}:</span> <span data-testid="delivery-method" style="text-transform: capitalize; font-weight: 500;">${formattedDeliveryMethod}</span>
                  </div>
                  <div style="padding-bottom: 2px">
                    <span>${Localise(
                      messages,
                      "Delivery Zone"
                    )}:</span> <span data-testid="delivery-zone" style="text-transform: capitalize;">${deliveryZoneName}</span>
                  </div>
                      <div class="delivery-address" style="padding: 10px 0;">
                          <div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${delName}</div>
                          ${
                            recipientInfo.locationName
                              ? `<div style="padding: 1px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">${locationName}</div>`
                              : ``
                          }
                                  <div style="padding: 1px 0;">
                                      <div style="padding: 1px 0;">${delAddress}${
      delAddress2 ? `, ${delAddress2}` : ``
    }</div>
                                      <div style="padding: 1px 0;">${cityStateAndZip}</div>
                                      <div style="padding: 1px 0;">${phoneNumberFormatter(
                                        delPhone
                                      )}</div>
                                  </div>
                          </div>
                      <div>
                    <div style="font-size: 12px; padding: 1px 0; margin-bottom: 20px;" data-testid="shopName_footer">${sendBusinessName}</div>
                    <div style="padding-top: 10px">${Localise(
                      messages,
                      "RECEIVED BY"
                    )}_____________________________</div>
                  </div>
                </div>
                <div class="right-col" style="font-weight:bold; width:50%;">
                      <div style="padding-bottom: 5px">${Localise(
                        messages,
                        isStorePickupOrder ? "Pickup Date" : "Delivery Date"
                      )}: ${moment(deliveryDate).format("ddd MM/DD/YYYY")}</div>
                      ${
                        pickOrDeliverByTime
                          ? `<div style="padding-bottom: 5px" data-testid="deliveryTime_footer">${Localise(
                              messages,
                              isStorePickupOrder
                                ? "Pickup Time"
                                : "Delivery Time"
                            )}: ${pickOrDeliverByTime}</div>`
                          : ``
                      }
                      ${
                        isStorePickupOrder
                          ? `<div style="padding-bottom: 15px"> ${Localise(
                              messages,
                              "Pickup By"
                            )}:<span class="fs-16 heading2"> ${pickUpBy}</span></div>`
                          : ``
                      }
                      ${
                        formattedDeliveryInstructions.length
                          ? `<div style="font-size: 12px;">${Localise(
                              messages,
                              "Delivery Instructions"
                            )}: ${formattedDeliveryInstructions}</div>`
                          : ``
                      }
             <div style="display:flex; flex-direction: row; justify-content: space-between; width: 100%; height: 70%;">
             <div style="font-size:12px !important;padding-top: 5px; font-weight: 600 !important; width: 80%;">${Localise(
               messages,
               "Products"
             )} ${getProductNames()}</div>
                <div style="width: 20%; justify-content: end; display: flex; align-items: end; padding-right: 5px;">${qrPlaceHolder(
                  qrImageData,
                  { width: 75, height: 75, position: "bottom" }
                )}</div>
                </div>
                </div>
              </div>`;
  };

  return `${
    isSinglePrint
      ? `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <title>Mercury HQ</title>
      </head>
      <body onload="window.parent.postMessage({ action: 'iframe-loaded' }, '*');">
        <div style="display: none">
          <style type="text/css" media="print,screen">
            ${getWorksheetCSS({ hasLocationName })}
          </style>
        </div>`
      : ``
  } 
        <div class=${
          Platform.OS == "ios" ? "" : "page-header"
        } style="text-align: center;width: 100%;">
          <div class="head-container">
              ${header()}
          </div>
          <hr />
          <div class="table-responsive" style="width: 100%;">
            <table class="table">
              <tbody>
                <tr
                  style="font-size: 12px !important; font-weight: bold !important"
                >
                  <td class="tableHeader">
                  </td>
                  <td class="tableHeader">
                    <label>${Localise(messages, "PRODUCT")}</label>
                  </td>
                  <td class="tableHeader">
                    <label>${Localise(messages, "QTY")}</label>
                  </td>
                  <td class="tableHeader">
                    <label>${Localise(messages, "PRICE/UT")}</label>
                  </td>
                  <td class="tableHeader">
                    <label>${Localise(messages, "DISCOUNT")}</label>
                  </td>
                  <td class="tableHeader">
                    <label>${Localise(messages, "EXTENDED")}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        ${
          Platform.OS == "ios"
            ? ""
            : `<div class="page-footer">
          <div class="foot-container">
         ${footer()}
        </div>
        </div>`
        }
        <div style="margin-top: 0px !important" class="page-break-flow-root">
          <div class="table-responsive">
            <table class="table content-table">
            ${
              Platform.OS == "ios"
                ? ""
                : `<thead>
                <tr>
                  <th>
                    <!--place holder for the fixed-position header-->
                    <div class="page-header-space">&nbsp;</div>
                  </th>
                </tr>
              </thead>`
            }
              <tbody>
                ${totalProducts
                  .map((product, index) => {
                    const {
                      productFirstChoiceCode = "",
                      productFirstChoiceDescription = "",
                      lineItemAmounts,
                      discountValue,
                      discountType,
                      quantity,
                      designNotes = "",
                      recipe = "[]",
                      accessoryDesc = "",
                      accessoryId = "",
                      accessoryPrice = "",
                      accessoryQty = "",
                    } = product;

                    const retailPrice = lineItemAmounts?.find(
                      (amount) => amount.name === "retailProductAmount"
                    ).value;
                    const productPrice = parseFloat(
                      retailPrice || accessoryPrice
                    );
                    const discount = discountValue
                      ? getDiscount(
                          Object.values(discountValue)[0],
                          productPrice,
                          discountType
                        )
                      : 0;
                    const discountedAmount =
                      productPrice - parseFloat(discount);
                    subTotal += discountedAmount;

                    const recipeContent = isSinglePrint
                      ? getSingleRecipeContent(productFirstChoiceCode)
                      : getMultipleRecipeContent(recipe);

                    return `<tr style="page-break-inside:avoid !important; ${
                      Platform.OS == "ios"
                        ? "display:inline-block;vertical-align:top;width:100%"
                        : ""
                    }">
                    <td style="padding-top: 5px;">${checkBoxPlaceholder()}</td>
                  <td>
                    <span style="font-size: 12px">${
                      productFirstChoiceCode || accessoryId
                    } ${
                      productFirstChoiceDescription || accessoryDesc
                    } ${designNotes} ${
                      recipeContent.length > 0
                        ? `<br/> <span style="font-size: 12px">RECIPE:</span> <table class="recipe-table">${recipeContent}</table>`
                        : ``
                    }</span>
                       
                  </td>
                  <td>
                    <div class="">
                      <span style="font-size: 12px">${
                        quantity || accessoryQty
                      }</span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span style="font-size: 12px">$${parseFloat(
                          (productPrice || accessoryPrice) /
                            (quantity || accessoryQty)
                        ).toFixed(2)}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span style="font-size: 12px">$${parseFloat(
                        discount
                      ).toFixed(2)}</span>
                    </div>
                  </td>
                  <td>
                    <div style="font-size: 12px">
                      <span style="font-size: 12px">$${formatPrice(
                        discountedAmount
                      )}
                      </span>
                    </div>
                  </td>
                </tr>`;
                  })
                  .join("")}
                  <tr style="${
                    getProductRecipeLength() >= 3
                      ? "page-break-after:always !important;"
                      : "page-break-inside:avoid !important;"
                  };border-bottom: 1px solid #666; border-top: 1px solid #ccc; font-size:12px !important; ">
                  <td colspan="3">    
                          <div style="font-size:12px !important;padding: 4px;">
                              <span style="font-weight: bold">${Localise(
                                messages,
                                "Card"
                              )}: </span>
                              <span class="cardMessage editorContent" style="font-weight: 500;">${formatCardMessage(
                                cardMessage
                              )}</span>
                          </div>
                      </td>    
                      <td colspan="6" style="padding-right:0px !important;">
                          <div style="display:flex; flex-direction: column;align-items: flex-end;">
                              <div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(messages, "SUBTOTAL")}</div>
                                  <div>$${parseFloat(subTotal).toFixed(2)}</div>
                              </div>
                              <div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(
                                    messages,
                                    "Delivery Fee"
                                  )}</div>
                                  <div>$${formatPrice(deliveryFee)}</div>
                              </div>
                              ${
                                hasRetailDeliveryFee
                                  ? `<div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(
                                    messages,
                                    "Retail Delivery Fee"
                                  )}</div>
                                  <div>$${formatPrice(retailDeliveryFee)}</div>
                              </div>`
                                  : ``
                              }
                              ${
                                hasRelayFee
                                  ? `<div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(messages, "Relay Fee")}</div>
                                  <div>$${formatPrice(relayFee)}</div>
                              </div>`
                                  : ``
                              }
                              ${
                                isMOLOrder &&
                                serviceFee > 0 &&
                                totalDeliveryFee > 0
                                  ? `<div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(
                                    messages,
                                    "Service Fee"
                                  )}</div>
                                  <div>$${formatPrice(serviceFee)}</div>
                              </div>`
                                  : ``
                              }
                              ${
                                hasMHQServiceFee
                                  ? `<div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(
                                    messages,
                                    "Service Fee"
                                  )}</div>
                                  <div>$${formatPrice(mhqServiceFee)}</div>
                              </div>`
                                  : ``
                              }
                              ${
                                hasRetransFee
                                  ? `<div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(
                                    messages,
                                    "Retrans Fee"
                                  )}</div>
                                  <div>$${formatPrice(retransFee)}</div>
                              </div>`
                                  : ``
                              }
                               <div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;">
                                  <div>${Localise(messages, "Sales Tax")}</div>
                                  <div>$${parseFloat(
                                    totalTax.amount || 0
                                  ).toFixed(2)}</div>
                              </div>    
                               <div style="display:flex; flex-direction: row;width: 300px;justify-content: space-between;padding: 4px;font-weight: bold;">
                                  <div>${Localise(messages, "TOTAL")}</div>
                                  <div>$${parseFloat(
                                    price.find(
                                      (obj) =>
                                        obj.name === "amountChargedToCustomer"
                                    )?.value || 0
                                  ).toFixed(2)}</div>
                              </div>     
                          </div>
                          
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <!--place holder for the fixed-position footer-->
                    <div class="page-footer-space">&nbsp;</div>
                  </td>
                </tr>
              </tfoot>
            </table> 
          </div>
        </div>
        ${
          isSinglePrint
            ? `</body>
    </html>`
            : ``
        }
       `;
};

export const getMultiWorksheetPrintHTML = ({
  eachOrder,
  operatorName,
  messages = {},
  Localise = () => {},
  iframeId = "iframe",
  qrImagesData,
  selectedproducts = [],
}) => {
  const orderItem = get(eachOrder, `orderDetails.orderItems.0`, {});
  const { recipientInfo = {}, orderItemId = "" } = orderItem;
  const { customerInfo = {}, paymentDetails = {} } = get(
    eachOrder,
    "orderDetails",
    {}
  );

  const qrImageData = qrImagesData[orderItemId];

  const { firstName = "", lastName = "", locationName = "" } = recipientInfo;

  const hasRecipientInfo = firstName.length > 0 || lastName.length > 0;
  const hasLocationName = hasRecipientInfo && locationName;
  const zoneDetails = [];

  return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
      <head>
        <title>Mercury HQ</title>
      </head>
      <body onload="window.parent.postMessage({ action: '${iframeId}-loaded' }, '*');">
        <div style="display: none">
          <style type="text/css" media="print,screen">
            ${getWorksheetCSS({ hasLocationName })}
          </style>
        </div>
        <div class="printOrderContainer">
          ${getSingleWorksheetPrintHTML({
            orderDetails: orderItem,
            operatorName,
            messages,
            Localise,
            selectedproducts,
            customerInfo,
            paymentDetails,
            zoneDetails,
            qrImageData,
            isSinglePrint: false,
          })}
        </div>
      </body>
    </html>`;
};
